<template>
  <div class="box">
    <div class="btn">
      <div style="display: flex;align-items:center;">
        <div class="logo">
          <img src="../assets/img/logoc.png" alt="">
        </div>
        <div style="margin-left: .2rem;">
          <div style="font-size: .4rem;">潮玩盒</div>
          <div>主播同款，点击即玩！</div>
        </div>
      </div>

      <div class="wxBtn" @click="goWx">
        打开微信小程序
      </div>
    </div>
    <img src="../assets/img/backBook.png" alt="" style="width: 100vw;height: 100%;object-fit: cover;">
    <div style="height: 1.3rem;width: 100%;"></div>
    <div class="wxBtn2" @click="goWx">
        打开微信小程序
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    goWx() {
      window.location.href = 'weixin://dl/business/?appid=wxa0531f249f431495&path=pages/box/box'
    }
  }
}
</script>
<style scoped lang="less">
.box {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

img {
  vertical-align: middle;
}

.clickBox {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
}

.btn {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .2rem;
}

.logo {
  box-shadow: inset 0px 0px .2rem 0px rgba(0, 145, 255, 1);
  padding: .2rem;
  border-radius: .3rem;

  img {
    width: 1.2rem;
    height: 1.2rem;
    object-fit: cover;
  }
}

.wxBtn {
  width: 2.6rem;
  text-align: center;
  height: 1rem;
  line-height: 1rem;
  font-size: .3rem;
  background-color: rgb(229, 114, 114);
  color: #fff;
  border-radius: .3rem;
}
.wxBtn2{
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 1.3rem;
  line-height: 1.3rem;
  font-size: .4rem;
  background-color: rgb(229, 114, 114);
  color: #fff;
  border-radius: .3rem .3rem 0 0;
}
</style>